@font-face {
  font-family: 'PolySansQonto';
  src: url('/fonts/PolySansQonto-Median.woff2') format('woff2'),
    url('/fonts/PolySansQonto-Median.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySansQonto';
  src: url('/fonts/PolySansQonto-Neutral.woff2') format('woff2'),
    url('/fonts/PolySansQonto-Neutral.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySansQonto';
  src: url('/fonts/PolySansQonto-Slim.woff2') format('woff2'),
    url('/fonts/PolySansQonto-Slim.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
