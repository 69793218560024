@import 'react-responsive-modal/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    @apply h-full text-darkblue;
    font-family: 'PolySansQonto', sans-serif;
    font-size: 16px;
  }

  body {
    @apply box-border pb-40 bg-neutral-50;
  }

  svg {
    @apply fill-current stroke-current;
  }

  * {
    @apply box-border;
  }

  .box-content * {
    box-sizing: content-box;
  }

  .container-h-full {
    min-height: calc(100vh - 9rem);
  }

  .outline-gray-50 {
    outline-color: theme('colors.gray.800');
  }

  button:focus {
    @apply outline-none;
  }

  .btn {
    @apply cursor-pointer bg-black hover:bg-slate-600 rounded-md text-white leading-4 flex items-center justify-center h-10 px-5 py-2 font-thin;
  }

  .text-title {
    @apply text-2xl leading-8 font-bold;
  }

  .text-subtitle {
    @apply text-sm text-grey-500 my-5;
  }

  .label-question {
    @apply text-sm;
  }

  .text-normal {
    @apply text-grey-100 text-base mt-1 leading-tight;
  }

  input[type='radio']:checked + label {
    @apply bg-caarlgreen text-caarlblue;
  }

  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='password'],
  input[type='number'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='week'],
  [multiple],
  textarea,
  select {
    @apply border border-grey-100 rounded-md text-sm bg-white px-3 py-2 mt-1;
  }

  .file-upload .form-input {
    @apply text-sm p-1;
  }

  form div.text-red-500 {
    @apply text-sm mt-1;
  }

  .radio-label {
    min-width: 120px;
    @apply inline-block m-2 px-4 text-center;
  }

  .container-centered {
    @apply mx-auto max-w-lg pb-12 px-4;
  }

  .fieldIcon {
    @apply bg-transparent bg-no-repeat bg-center z-10 absolute w-8;
    content: '';
    left: 2px;
    top: 2px;
    height: calc(100% - 4px);
  }

  .menu-content {
    transform: translateY(10px);
    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
  }

  .menu-toggle {
    cursor: pointer;
  }

  .menu-toggle:hover + .menu-content,
  .menu-content:hover {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  header.auth {
    @apply bg-caarlgreen;
    transform: rotate(7deg);
  }
  header.auth > * {
    transform: rotate(-7deg);
  }

  /* For a bad IOS bug : https://github.com/twbs/bootstrap/issues/23307 */
  input[type='date'] {
    min-height: 2.5rem;
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-grey-300;
  }

  .checkbox {
    @apply p-2 font-normal;
  }

  .form-error {
    @apply text-red-500 font-normal text-xs mt-1;
  }
}

@layer utilities {
}

.react-responsive-modal-modal {
  @apply !py-12 !px-8;
}

.disabled,
*:disabled {
  @apply bg-opacity-40 pointer-events-none;
}
.disabled .disabled,
*:disabled .disabled,
.disabled *:disabled,
*:disabled *:disabled {
  @apply opacity-100;
}

#chFrame h1 {
  @apply text-xl font-bold mb-4;
}
#chFrame h2 {
  @apply text-base font-bold mx-4;
}
#chFrame p {
  @apply my-2;
}
